.reserve-scheduler-container * {
  box-sizing: initial !important;
}
.reserve-scheduler-container {
  max-height: 70vh;
  overflow-y: scroll;
}
.reserve-scheduler-container .rs__event__item {
  /* Temporary fix for events not being full width */
  width: 100% !important;
}

.tutor-info * {
  z-index: 1000;
}
.modal-reserve {
  z-index: 100000;
}
