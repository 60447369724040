/* .schedule__calender {
  width: 80%;
  margin: 2rem auto;
} */

/* @media screen and (max-width: 768px) {
  .schedule__calender {
    width: 90%;
  }
} */
.scheduler-container * {
  box-sizing: initial !important;
}
.scheduler-container {
  max-height: 70vh;
  overflow-y: scroll;
}
