.notion-renderer {
  text-align: start;
  margin: auto;
}
/* .notion-page-icon-hero {
  width: 100%;
  position: inherit;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
} */
.notion-title {
  text-align: inherit;
}

/* .notion-page-icon {
  display: none;
} */
