* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-size: 20px;
  /* background: rgb(238, 174, 202); */
  /* background: radial-gradient(
      circle,
      rgba(238, 174, 202, 1) 0%,
      rgba(199, 233, 148, 1) 100%
    ); */
}

h1 {
  text-align: center;
  margin: 2rem 0 4rem 0;
}

.accordion {
  /* max-width: 600px; */
  margin: 2rem auto;
}

.accordion-item {
  margin: 2rem;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: #21aeca; */
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4%;
}

/* .accordion-title:hover {
    background-color: #3ab4cc;
  } */

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  /* background-color: #39b9d2; */
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}

.notion-header {
  display: none;
}
