.classes-scheduler-container * {
  /* chakra ui sets this as border-box, which breaks height calculations */
  box-sizing: initial !important;
}
.classes-scheduler-container {
  max-height: 55vh;
  overflow-y: scroll;
}
.classes-scheduler-container .rs__event__item {
  /* Temporary fix for events not being full width */
  width: 100% !important;
}
