.dropdown {
  position: relative;
}

.menu {
  position: absolute;
  top: 70px;
  left: calc(170px - 20%) !important;
  /* left: calc(100px + 50%); */
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  z-index: 10;
  border: 1px solid grey;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  /* border-radius: 30%; */
  width: calc(60% - 20px) !important;
  background-color: white;
}

.menu > li {
  margin: 0;
  background-color: white;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.select-container * {
  z-index: 100;
}
